import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/manager/machines/:machine_id/property', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/machines/:machine_id/property', params, payload, config)
  },
}
