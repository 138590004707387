<template lang="pug">
  b-card.manager-machines-property-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-form.form-wc.form-machines-property-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-property-travel_inclination(
              :label="$t('activerecord.attributes.machine/property.travel_inclination')"
              label-for='property-travel_inclination'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#property-travel_inclination.form-input-first.shadow-sm.is-required(
                name='travel_inclination'
                type='text'
                autocomplete='travel_inclination'
                :placeholder="$t('machine/property.placeholders.travel_inclination')"
                :state='formValidationState($v.form.data.attributes.travel_inclination)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.travel_inclination.$model'
                aria-describedby='property-travel_inclination-feedback'
                trim)
              wc-forms-if#property-travel_inclination-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.travel_inclination'
                :remote="formRemoteInvalidFeedback('travel_inclination')"
                :validators='{ required: {}, numeric: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-property-lateral_inclination(
              :label="$t('activerecord.attributes.machine/property.lateral_inclination')"
              label-for='property-lateral_inclination'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#property-lateral_inclination.form-input-middle.shadow-sm.is-required(
                name='lateral_inclination'
                type='text'
                autocomplete='lateral_inclination'
                :placeholder="$t('machine/property.placeholders.lateral_inclination')"
                :state='formValidationState($v.form.data.attributes.lateral_inclination)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.lateral_inclination.$model'
                aria-describedby='property-lateral_inclination-feedback'
                trim)
              wc-forms-if#property-lateral_inclination-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.lateral_inclination'
                :remote="formRemoteInvalidFeedback('lateral_inclination')"
                :validators='{ required: {}, numeric: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-property-battery(
              :label="$t('activerecord.attributes.machine/property.battery')"
              label-for='property-battery'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#property-battery.form-input-middle.shadow-sm.is-required(
                name='battery'
                type='text'
                autocomplete='battery'
                :placeholder="$t('machine/property.placeholders.battery')"
                :state='formValidationState($v.form.data.attributes.battery)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.battery.$model'
                aria-describedby='property-battery-feedback'
                trim)
              wc-forms-if#property-battery-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.battery'
                :remote="formRemoteInvalidFeedback('battery')"
                :validators='{ required: {}, greaterEqual: { number: 0 } }')

            b-form-group#form-property-delivered(
              :label="$t('activerecord.attributes.machine/property.delivered')"
              label-for='property-delivered'
              label-cols-md='2'
              label-align-md='right')
              flat-pickr#property-delivered.form-control.form-input-last.shadow-sm.w-50(
                name='delivered'
                :placeholder="$t('machine/property.placeholders.delivered')"
                :class='formValidationClass($v.form.data.attributes.delivered)'
                :config='form.datetime.delivered.config'
                @focus.native='formChange'
                v-model.trim='$v.form.data.attributes.delivered.$model'
                aria-describedby='property-delivered-feedback')
              wc-forms-if#property-delivered-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.delivered'
                :remote="formRemoteInvalidFeedback('delivered')"
                :validators='{ dateFormat: {}, maxLength: {} }')

            b-form-group(
              :label="$t('activerecord.attributes.shared.description')"
              label-cols-md='12'
              label-size='lg')

              wc-forms-translations(
                v-for='(locale, key) in $wc.conf.base.locales'
                :key='`description_${key}`'
                :locale='locale'
                :lc='key')
                b-collapse(
                  :id='`tl-description_${key}`'
                  :ref='`description_${key}Tl`'
                  :visible="action === 'create' ? true : formShowSection(key, 'Description')"
                  role='tabpanel')
                  b-card-body
                    b-form-group(
                      :id='`form-property-description_${key}`'
                      :label="$t('activerecord.attributes.shared.description') + ' ' + $t(`locales.${key}`)"
                      label-for='`property-description_${key}`'
                      label-cols-md='2'
                      label-align-md='right')
                      b-form-textarea.shadow-sm(
                        :id='`property-description_${key}`'
                        name='`description_${key}`'
                        :placeholder="$t('machine/property.placeholders.description')"
                        :state='formValidationState($v.form.data.attributes[`description_${key}`])'
                        @focus.native='formChange'
                        v-model='$v.form.data.attributes[`description_${key}`].$model'
                        aria-describedby='`property-description_${key}-feedback`'
                        rows='4'
                        max-rows='8'
                        trim)
                      wc-forms-if(
                        :id='`property-description_${key}-feedback`'
                        extra-class='ml-feedback'
                        :attribute='$v.form.data.attributes[`description_${key}`]'
                        :remote="formRemoteInvalidFeedback(`description_${key}`)"
                        :validators='{ maxLength: {} }')

            b-form-group(
              :label="$t('activerecord.attributes.machine/property.note')"
              label-cols-md='12'
              label-size='lg')

              wc-forms-translations(
                v-for='(locale, key) in $wc.conf.base.locales'
                :key='`note_${key}`'
                :locale='locale'
                :lc='key')
                b-collapse(
                  :id='`tl-note_${key}`'
                  :ref='`note_${key}Tl`'
                  :visible="action === 'create' ? true : formShowSection(key, 'Note')"
                  role='tabpanel')
                  b-card-body
                    b-form-group(
                      :id='`form-property-note_${key}`'
                      :label="$t('activerecord.attributes.machine/property.note') + ' ' + $t(`locales.${key}`)"
                      label-for='`property-note_${key}`'
                      label-cols-md='2'
                      label-align-md='right')
                      b-form-textarea.shadow-sm(
                        :id='`property-note_${key}`'
                        name='`note_${key}`'
                        :placeholder="$t('machine/property.placeholders.note')"
                        :state='formValidationState($v.form.data.attributes[`note_${key}`])'
                        @focus.native='formChange'
                        v-model='$v.form.data.attributes[`note_${key}`].$model'
                        aria-describedby='`property-note_${key}-feedback`'
                        rows='4'
                        max-rows='8'
                        trim)
                      wc-forms-if(
                        :id='`property-note_${key}-feedback`'
                        extra-class='ml-feedback'
                        :attribute='$v.form.data.attributes[`note_${key}`]'
                        :remote="formRemoteInvalidFeedback(`note_${key}`)"
                        :validators='{ maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-property-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/machines/shared/tabs'
import WcFormsTranslations from '@components/shared/forms/WcFormsTranslations'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiProperty from '@services/api/manager/machines/property'
import { Form } from '@common/form'
import { dateFormat, greaterEqual } from '@common/form/validations'
import { required, maxLength, numeric } from 'vuelidate/lib/validators'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'manager-machines-property-form',
  mixins: [Tabs, Form],
  components: {
    WcFormsTranslations,
    WcFormsButtons,
    FlatPickr,
  },
  computed: {
    apiParams() {
      return {
        get: [{ machine_id: this.machine_id }, {}],
        update: [
          { machine_id: this.machine_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('property-changed')
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            travel_inclination: {
              required,
              numeric,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('travel_inclination'),
            },
            lateral_inclination: {
              required,
              numeric,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('lateral_inclination'),
            },
            battery: {
              required,
              greaterEqual: greaterEqual(0),
              remote: () => this.formRemoteValid('battery'),
            },
            delivered: {
              dateFormat: dateFormat(this.$t('date.view.format')),
              maxLength: maxLength(this.$wc.conf.limits.max_date),
              remote: () => this.formRemoteValid('delivered'),
            },
          },
        },
      },
    }
    for (let locale of this.$wc.conf.base.available_locales) {
      validations.form.data.attributes[`description_${locale}`] = {
        maxLength: maxLength(this.$wc.conf.limits.max_description),
        remote: () => this.formRemoteValid(`description_${locale}`),
      }
      validations.form.data.attributes[`${locale}DescriptionTl`] = [
        `form.data.attributes.description_${locale}`,
      ]
      validations.form.data.attributes[`note_${locale}`] = {
        maxLength: maxLength(this.$wc.conf.limits.max_description),
        remote: () => this.formRemoteValid(`note_${locale}`),
      }
      validations.form.data.attributes[`${locale}NoteTl`] = [`form.data.attributes.note_${locale}`]
    }
    return validations
  },
  data() {
    return {
      apiBase: apiProperty,
      apiModel: 'property',
      machine_id: this.$route.params.machine_id,
      formBootstrapDefaults: {
        all: {
          datetime: {
            delivered: {
              config: {
                allowInput: true,
                maxDate: 'today',
                ariaDateFormat: this.$t('date.formats.default').replace(/%/gi, ''),
                dateFormat: this.$t('date.formats.default').replace(/%/gi, ''),
                locale:
                  this.$store.getters['ui/getLocale'] !== this.$wc.conf.base.default_locale
                    ? require(`flatpickr/dist/l10n/${this.$store.getters['ui/getLocale']}.js`)
                        .default[this.$store.getters['ui/getLocale']]
                    : this.$wc.conf.base.default_locale,
              },
            },
          },
        },
      },
    }
  },
}
</script>
